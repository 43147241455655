import { Swiper, Navigation } from 'swiper'
const companyPresentation = new Swiper('.company-presentation__swiper', {
  direction: 'horizontal',
  spaceBetween: 30,
  navigation: {
    prevEl: <HTMLElement>document.querySelector('.company-presentation__nav-btn.--prev'),
    nextEl: <HTMLElement>document.querySelector('.company-presentation__nav-btn.--next'),
  },
  modules: [Navigation],
})

const directionsPresentation = new Swiper('.directions__swiper', {
  direction: 'horizontal',
  breakpoints: {
    1200: { slidesPerView: 3, spaceBetween: 40 },
    800: {slidesPerView: 2}
  },
  slidesPerView: 1,
  spaceBetween: 12,
})

const awards = new Swiper('.awards__swiper', {
  direction: 'horizontal',
  breakpoints: {
    1200: { slidesPerView: 3, spaceBetween: 40 },
    800: {slidesPerView: 2}
  },
  slidesPerView: 1,
  spaceBetween: 12,
  navigation: {
    prevEl: '.awards__nav-btn_left',
    nextEl: '.awards__nav-btn_right',
  },
  modules: [Navigation],
})

